import { storage } from '../../../firebase.config.js'
import axios from 'axios'
import * as rax from 'retry-axios'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    SendWarningImageToStorage(context, payload) {
        return storage.ref().child(payload.path).child(payload.name).put(payload.file)
    },
    SendWarningImageToStorageBase64(context, payload) {
        let FileName = payload.flag + '/' + new Date(payload.datetime).toLocaleDateString('pt-BR').split('/').join('-') + '/sauron-assistido-' + payload.datetime
        return storage.ref().child('sauronimages3/' + FileName).putString(payload.classifiedImage.split(',')[1], 'base64', { contentType: 'image/jpg' })
    },

    // async GetSauronImageDownloadLink(context, payload) {
    //     console.log(payload)
    // },
    GetSauronImageDownloadLink(context, payload) {
        
        return storage.ref().child('sauronimages3/' + payload + '.jpg').getDownloadURL()
    },
    async GetMordorImageDownloadLink(context, payload) {
        console.log('entrou')
        const interceptorId = rax.attach() // eslint-disable-line
        // let url = 'https://firebasestorage.googleapis.com/v0/b/celso-sandbox.appspot.com/o/sauronimages3%2F' + payload.replaceAll('/', '%2F') + '.jpg'
        let url = 'https://firebasestorage.googleapis.com/v0/b/altave-harpia.appspot.com/o/sauronimages3%2F' + payload.replaceAll('/', '%2F') + '.jpg'
        // let url = 'https://firebasestorage.googleapis.com/v0/b/altave-harpia.appspot.com/o/sauronimages3%2Faltave-backlog%2F' + payload.replaceAll('/', '%2F') + '.jpg'
        
        // let url = 'https://firebasestorage.googleapis.com/v0/b/altave-harpia.appspot.com/o/sauronimages3%2Faltave-backlog%2F' + payload.replaceAll('/', '%2F') + '.jpg'
        // let url = 'https://firebasestorage.googleapis.com/v0/b/altave-harpia.appspot.com/o/sauronimages3%2Fcodeba%2F' + payload.replaceAll('/', '%2F') + '.jpg'
        return axios({
            url: url,
            raxConfig: {
                retry: 3,
                noResponseRetries: 3,
                retryDelay: 5000,
                statusCodesToRetry: [
                    [100, 199],
                    [404, 429],
                    [500, 599]
                ],
                backoffType: 'static',
            }
        }).then(result => {
            return result.request.responseURL + '?alt=media&token=' + result.data.downloadTokens
        }).catch(error => { return error })
    },
    GetMordorDemandImageDownloadLink(context, payload) {
        return storage.ref().child('mordor-on-demand/' + payload + '.jpg').getDownloadURL()
    },
    GetMordorDemandImageDownloadLinkIR(context, payload) {        
        return storage.ref().child('mordor-on-demand/ir-' + payload + '.jpg').getDownloadURL()
    },
    GetMordorDemandImageDownloadLinkTV(context, payload) {        
        return storage.ref().child('mordor-on-demand/tv-' + payload + '.jpg').getDownloadURL()
    },
    GetMordorDemandImageDownloadLinkCarousel(context, payload) {        
        const objetoJason={}        
        payload.presets.forEach(preset => {   
            storage.ref().child('mordor-on-demand/tv-' + payload.company + '-' + preset + '.jpg').getDownloadURL()
            .then(url => {                
                objetoJason[preset] = url                
            })                 
        })
         return objetoJason
        
        
    },
    GetMordorTemplateImageDownloadLink(context, payload) {
        return storage.ref().child('mordor-templates/mordor-03-trovao/' + payload + '.png').getDownloadURL()
    },
    GetGeoTiffDownloadLink(context, payload) {
        return storage.ref().child('geotiff/' + payload).getDownloadURL()
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}