import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const locale = navigator.language || navigator.userLanguage;

export default new VueI18n({
  locale,
  messages: {
    "pt-BR": {
      login: {
        email: "Email",
        password: "Senha",
        confirm: "Confirmar",
      },
      navbar: {
        transmission: "Transmissão",
        warnings: "Alertas",
        sauron: "SAURON Assistido",
        sauronAux: "SAURON Auxiliar",
        administration: "Administração",
        operation: "Operação",
        mordor: "MORDOR sob demanda",
        language: "Idioma",
        logout: "Sair",
      },
      home: {
        loadingTransmissions: "Carregando transmissão... ",
      },
      fab: {
        mainTooltip: "Mostrar opções",
        georefTooltipOn: "Habilitar georreferenciamento",
        georefTooltipOff: "Desabilitar georreferenciamento",
      },
      tour: {
        main: "Bem vindo ao ALTAVE Harpia! <br> Este é um breve guia para auxiliar sua navegação. Clique em próximo para continuar.",
        navbar:
          "Esta é a barra de navegação principal do Harpia. Aqui, aparecem as opções para acompanhar a operação.",
        operation:
          "Esta é a caixa de seleção de Operação. Ela mostra as Operações disponíveis para o usuário acompanhar nesta conta.",
        transmission:
          "Aqui é onde é mostrado o vídeo da câmera do balão em tempo real.",
        options:
          "Este é o botão que mostra opções adicionais para a transmissão ao vivo. Quando disponível, você pode clicar sobre ele e ativar a visualização do georreferenciamento câmera.",
        labels: {
          buttonSkip: "Pular guia",
          buttonPrevious: "Anterior",
          buttonNext: "Próximo",
          buttonStop: "Finalizar",
        },
      },
      sensors: {
        table: {
          id: "Sensor",
          time: "Último alarme recebido",
          elapsed: "Tempo desde último alarme",
          elapsedHoursAgo: " hora(s) atrás",
          message: "Tipo do alarme",
          battery: "Tensão da bateria",
        },
        notification: {
          message: "Há novo alerta de movimentação em um dos sensores.",
        },
        legend: {
          falseAlarm: "ALARME FALSO",
          trueAlarm: "ALARME VERDADEIRO",
          seen: "VISUALIZADO",
          notSeen: "NÃO VISUALIZADO",
          confirmed: "confirmado por operador",
          operator: "por operador",
        },
      },
      warnings: {
        calendar: {
          label: "Selecione uma data",
          button: "Confirmar",
        },
        card: {
          types: {
            person: "indivíduo | indivíduo | indivíduos",
            vehicle: "veículo | veículo | veículos",
            interest:
              "ponto de interesse | ponto de interesse | pontos de interesse",
            boat: "embarcação | embarcação | embarcações",
            light: "luz | luz | luzes",
            hotPoint: "hot point",
            fire: "incêndio | incêndio | incêndios",
            epi: "EPI"
          },
          detected: "detectado | detectado | detectados",
          date: "Data",
          hour: "Hora",
          point: "Ponto",
          conjunction: "e",
          region: "Região não informada"
        },
      },
      management: {
        sidemenu: {
          dashboard: "Visão geral",
          userInsert: "Inserir usuário",
          userModify: "Alterar usuário",
          hirerInsert: "Inserir operação",
          operationReport: "RERO/RHRO",
          livestreamInsert: "Inserir transmissão",
          reroRhro: "RERO/RHRO",
          warningInsert: "Inserir alerta",
          reportUserPresence: "Acessos de usuários",
          reportOperationReport: "Disponibilidade de voo",
          mordorOperationReport: "Dados do Mordor",
          reportRonda: 'Dados da ronda',
          about: "Sobre",
          labels: {
            dashboard: "Dashboard",
            userControl: "Controle de usuários",
            operationReport: "Reportes",
            livestreamControl: "Controle de transmissões",
            warningControl: "Controle de alertas",
            reports: "Relatórios",
            about: "Ajuda",
            requestrecording: "Solicitar Gravações",
          },
        },
        dashboard: {
          welcome: "Bem vindo, ",
          onlineUsers: "Usuários online agora",
          userTable: {
            user: "Usuário",
            state: "Estado",
            company: "Empresa"
          },
          card: {
            condition: "Condição atual",
            flightNoProblem: "Tempo em voo sem problemas",
            anchored: "Tempo ancorado",
            days: "dias",
            hours: "horas",
            minutes: "minutos",
            seconds: "segundos",
            totalHours: "horas totais",
            and: "e",
          },
        },
      },
      opReport: {
        rhro: "RHRO",
        rero: "RERO",
        type: "Tipo",
        opStatus: {
          flyNoProblemn: "Balão voando sem problemas",
          flyWithProblemn: "Balão voando com problemas",
          aerostatDock: "Balão ancorado",
          startFlight: "Início de voo",
          altitudeChange: "Mudança de altitude",
          docking: "Recolhimento completo",
        },
        author: "Autor",
        dateTime: "Data e hora",
        problemOrigin: "Origem do problema",
        factorIntern: "Fatores Internos",
        factorExtern: "Fatores Externos",
        monitoringRoutine: "Rotina de monitoramento",
        monitorStatus: {
          automaticTour: "Ronda automática",
          manualMonitoring: "Monitoramento Manual",
        },
        liveStream: "Transmissão ao vivo",
        dockingCause: "Motivo da ancoragem",
        meteorologic: "Meteorologia",
        repair: "Manutenção",
        preFly: "Procedimentos pré-voo",
        training: "Treinamento",
        offWorkers: "Sem expediente",
        othersExt: "Outros - Fatores Externos",
        othersInt: "Outros - Fatores Internos",
        repairType: "Tipo de manutenção",
        repairCorrective: "Manutenção Corretiva",
        repairPrevent: "Manutenção Preventiva",
        equip: "Equipamento",
        liveStatus: {
          operatingLive: "Operando com transmissão ao vivo",
          operatingNoLive: "Operando sem transmissão ao vivo",
        },
        cableReleased: "Cabo liberado (Metros)",
        hoursContinuos: "Horas de voo contínuo",
        minutesContinuos: "Minutos de voo contínuo",
        modelCam: "Modelo de câmera",
        irCooler: "IR Cooler câmera",
        irStatus: {
          on: "Ligado",
          off: "Desligado",
          dontExist: "Não existe",
        },
        snGondola: "S/N Gôndola",
        pnGondola: "P/N Gôndola",
        extEnvelope: "Nylon",
        dateInstallNylon: "Data de instalação do Nylon",
        tpu: "TPU",
        dateInstallTPU: "Data de instalação do TPU",
        report: "Reporte",
        windyGo: "Acessar Windy",
        windSpeed: "Vento (kt)",
        gusts: "Rajada (kt)",
        tupanGo: "Acessar Tupan",
        tupanStatus: {
          flyAllow: "Voo Liberado",
          recoverAerostat: "Baixe o balão",
          noInfo: "Sem Informações",
        },
        localStation: "Estação anemométrica local",
        codebaGo: "Acessar estação CODEBA",
        codebaStatus: {
          windSpeed: "Vento (kt)",
          atmosphericPressure: "Pressão atmosférica (hPa)",
          windDirection: "Direção do vento",
          windRose: "Rosa dos ventos",
        },
        anemometer: "Anemômetro",
        cemadenGo: "Acessar CEMADEN",
        reflectivity: "Reflexividade (dBZ)",
        geocamStatus: {
          cableSlope: "Inclinação do cabo",
          aerostatAltitude: "Altitude do balão",
        },
        lightiningDetector: "Detector de raios",
        distance: "Distância (km)",
        eletricalField: "Campo elétrico",
        finish: "Finalizar",
        reportFinish: {
          windy: {
            windSpeed: "Velocidade do vento",
            gusts: "Velocidade de rajada:",
          },
          tupan: {
            flightReleased: "Voo liberado",
            downBallon: "Baixe o balão",
            status: "Status",
          },
          anenomometer: {
            windSpeed: "Velocidade do vento",
          },
          cemaden: {
            cloudReflex: "Reflexividade de nuvens",
          },
          geocam: {
            cableSlope: "Inclinação do cabo",
            aerostatAltitude: "Altitude do balão",
          },
        },
      },
      confirm: "Confirmar",
      insertLive: {
        broadcastProvider: "Provedor de transmissão",
        url: "URL/ Nome da transmissão de vídeo",
        description: "Descrição",
        operation: "Operação",
      },
      insertWarning: {
        operation: "Operação",
        event: "Ocorrência",
        selectImage: "Escolher imagem salva no computador",
      },
      about: {
        title: "Guia rápido",
        menubar: {
          overview: "Visão geral",
          insertUser: "Inserir usuário",
          changeUser: "Alterar usuário",
          eorrHorr: "RERO/RHRO",
          insertLivestream: "Inserir transmissão",
          insertWarning: "Inserir alerta",
          usersAccesses: "Acessos de usuários",
          flightAvailability: "Disponibilidade de voo",
        },
        permissions: {
            permissions: "Permissões",
            textInsertUser: "Campo para especificar as permissões de acesso a determinadas funções do Harpia.",
            textChangeUser: 'Altera as permissões de acesso a determinadas funções do Harpia.',
            streaming: "Transmissão",
            streamingText:
              "Controla o acesso a visualizar a transmissão ao vivo da câmera.",
            georef: "Georreferenciamento",
            georefText:
              "Acesso ao georreferenciamento da câmera em uma operação.",
            cameraControl: "Controle de câmera",
            cameraControlText:
              "Controla a permissão de controle da camera através do Harpia.",
            viewAlerts: "Visualizar Alertas",
            viewAlertsText: "Controla a permissão de visualizar os alertas.",
            deleteAlerts: "Excluir Alertas",
            deleteAlertsText: "Controla a permissão de excluir os alertas.",
            sauron: "Sauron",
            sauronText: "Controla a permissão de acesso ao Sauron assistido.",
            mordor: "Mordor",
            mordorText: "Controla a permissão de acesso ao Mordor on demand",
        },
        role: {
            role: "Papel",
            textInsertUser: "Campo para seleção do perfil do usuario, baseado nas funções que sera executada nesse login.",
            textChangeUser: 'Campo para modificação do perfil do usuario, baseado nas funções que sera realizada pelo usuário.',
            supervisorText: "Perfil para usuarios supervisores de operação.",
            operator: "Operador",
            operatorText: "Perfil para operadores de campo da operação.",
            viewer: "Visualizador",
            viewerText:
              "Perfil para utilização de exclusivo para visualizações.",
          },
        overview:
          "O objetivo do campo Visão Geral, no contexto Dashboard, é exibir os usuarios que estão conectados no Harpia no momento e de qual estado esta sendo realizada a conexão.",
        insertUser: {
          text: "Na opção Inserir Usuário, no contexto de Controle de Usuários, o objetivo é cadastrar os usuários e suas permissões.",
          name: "Nome",
          nameText:
            "Campo para inserção do nome do usuário a ser cadastrado. Este campo não pode ser alterado posteriormente.",
          emailText:
            "Campo para inserção do email do usuário. É permitido um unico perfil por email cadastrado.",
          password: "Senha",
          passwordText:
            "Campo para cadastro de senha inicial. Ao efetuar o cadastro, um email é enviado ao usuário para substituição de senha.",
          operation: "Operação/Empregador",
          operationText:
            "Campo para seleção das operações que o usuario cadastrado podera atuar.",
        },
        changeUser: {
          text: "Na opção Alterar Usuário, no contexto de Controle de Usuários, o objetivo é modificar as permissões que os usuários possuem ou excluir o usuário do sistema. Não é possivel alterar o nome e email dos usuários.",
          flag: "Flag",
          flagText:
            "Permite a inclusão e remoção das operações que o usuario cadastrado podera atuar.",
        },
        eorrHorr: {
          text: "No contexto de Reportes, a opção RERO/RHRO é utilizada para inserção de informações referentes a operação. Os campos abaixo são exibidos de acordo com a opção selecionada em status e podem ser preenchidos automaticamente, conforme informações do reporte anterior. Após o preenchimento dos dados deste formulario, é gerado automaticamente, uma mensagem padronizada para envio através do Whatsapp.",
          type: 'Tipo',
          typeText: 'Campo para seleção do tipo de relatório que esta sendo incluido.',
          status: 'Status',
          statusText: 'Campo para selecionar o status do aeróstato no momento da inserido.',
          author: 'Autor',
          authorText: 'Campo para inserir o nome do autor do reporte.',
          mooringReason: 'Motivo de ancoragem',
          mooringReasonText: 'Campo para informar o motivo pelo qual o aeróstato esta ancorado.',
          problemOrigin: 'Origem do problema',
          problemOriginText: 'Campo para informar qual a origem do problema que causou a ancoragem.',
          monitoringRoutine: 'Rotina de monitoramento',
          monitoringRoutineText: 'Campo para selecionar o tipo de monitoramento que esta sendo efetuado.',
          liveBroadcast: 'Transmissão ao vivo',
          liveBroadcastText: 'Campo para informar se a transmissão esta ativa.',
          cableReleased: 'Cabo liberado',
          cableReleasedText: 'Campo para informar a quantidade, em metros, de cabo liberado do aeróstato.',
          flightTime: 'Tempo de voo',
          flightTimeText: 'Dividido em horas e minutos, estes campos são para inserção de tempo de voo.',
          plc: 'PLC',
          plcText: "Dividido entre download e upload, estes campos são para informar o desempenho dos PLC's.",
          cameraData: 'Dados de camera',
          cameraDataText: 'Dividido entre Modelo e IR Cooler, estes campos são para reportar dados da camera que esta em operação no momento.',
          shelfData: 'Dados da gôndola',
          shelfDataText: 'Dividido entre Serial Number e Part Number, estes campos são para informações referentes a gôndola.',
          airshipData: 'Dados do aeróstato',
          airshipDataText: 'Dividido em quatro campos; TPU, Nylon e suas respectivas datas de instalação. Estes campos são para inserções de informações do aeróstato.',
          report: 'Reporte',
          reportText: 'Campo para inserção de dados, observações e outras informações que são relevantes a operação.',
          meteorologicalData: 'Dados Meteorologicos',
          meteorologicalDataText: 'Neste campo, é possivel inserir informações referentes a meteorologia local, fornecida por diversas fontes diferentes, como por exemplo: Windy, Cemaden, Tupan e equipamentos de medição local.'
        },
        insertLivestream: {
          text: 'No contexto de Controle de Transmissões, a opção Inserir Transmissão é utilizado para o iniciar a transmissão ao vivo das cameras pelo Harpia. Para realizar essa inserção, a transmissão via streaming deve ter sido criada no YouTube ou Vimeo.',
          videoTransmissionURL: 'URL de transmissão de vídeo',
          videoTransmissionURLText: 'Neste campo deve-se inserir o endereço do video na plataforma de streaming.',
          description: 'Descrição',
          descriptionText: 'Campo para inserir a descrição do video',
          operation: 'Operação/Empregador',
          operationText: 'Campo para selecionar a operação ao qual deseja que o video seja transmitido.'
        },
        insertWarning: {
          text: 'No contexto de Controle de Alertas, a opção Inserir Alerta serve para o cadastro de imagens que contenham qualquer alerta visualizados durante a transmissão.',
          latitude: 'Latitude',
          latitudeText: 'Campo para inserir a Latitude do ponto onde foi visualizado o alerta.',
          longitude: 'Longitude',
          longitudeText: 'Campo para inserir a Longitude do ponto onde foi visualizado o alerta.',
          operation: 'Operação/Empregador',
          operationText: 'Campo para selecionar a operação ao qual o alerta pertence.',
          occurrence: 'Ocorrência',
          occurrenceText: 'Campo para seleção do tipo de alerta que foi visualizado.',
          chooseImage: 'Escolher imagem salva no computador',
          chooseImageText: 'Campo utilizado para salvar a imagem referente ao alerta que esta sendo cadastrado.'
        },
        usersAccesses: {
          text: 'No contexto de Relatórios, a opção Acessos de Usuários serve para a extração de um relatório que contém as informações de login dos usuarios do Harpia. Para extração desses dados, basta selecionar a data ou periodo ao qual se deseja e confirmar. As informações fornecidas são:',
          user: 'Usuário',
          userText: 'Informa o login do usuário.',
          region: 'Região',
          regionText: 'Informa o estado ao qual o usuario utilizou para efetuar o acesso.',
          city: 'Cidade',
          cityText: 'Informa a cidade ao qual o usuario utilizou para efetuar o acesso.',
          ip: 'Endereço IP',
          ipText: 'Informa o endereço utilizado pelo usuário.',
          action: 'Ação',
          actionText: 'Informa a ação de conexão (online), desconexão (offline) do usuário.',
          date: 'Data',
          dateText: 'Informa o dia em que a ação foi registrada.',
          time: 'Hora',
          timeText: 'Informa a hora em que a ação foi registrada.'
        },
        flightAvailability: {
          text1: 'No contexto de Relatórios, a opção Disponibilidade de voo serve para a extração de um relatório que contém as informações referentes ao tempo de voo, baseado nos reportes inseridos no Harpia.',
          text2: 'Para extração desses dados, basta selecionar a data ou periodo ao qual se deseja e confirmar. As informações fornecidas são:',
          type: 'Tipo',
          typeText: 'Informa o tipo de reporte que foi inserido.',
          date: 'Data',
          dateText: 'Informa o dia em que o reporte foi registrado.',
          time: 'Hora',
          timeText: 'Informa a hora em que o reporte foi registrado.',
          notes: 'Observações',
          notesText: 'Informa os dados inseridos no campo Reporte do formulario RERO/RHRO.',
          altitude: 'Altitude',
          altitudeText: 'Informa os dados inseridos no campo cabo liberado do formulario RERO/RHRO.',
          meteorologicalData: 'Dados Meteorológicos',
          meteorologicalDataText: 'Informa os dados inseridos no campo de dados meteorológicos do formulario RERO/RHRO.',
          status: 'Status',
          statusText: 'Informa os dados inseridos no campo de status do formulario RERO/RHRO.',
          stopCause: 'Causa da parada',
          stopCauseText: 'Informa os dados inseridos no campo de Motivo da Ancoragem do formulario RERO/RHRO.',
          author: 'Autor',
          authorText: 'Informa o autor do formulario RERO/RHRO.'
        }
      },
      sauronAux: {
        target: 'Alvo',
        person: "Pessoa",
        vehicle: "Veículo",
        interestPoint: 'Ponto de interesse',
        hotpoint: 'Ponto quente',
        light: 'Luz',
        boat: 'Barco',
        fire: 'Incêndio',
        save: "Salvar",
        undo: 'Desfazer',
        clean: 'Limpar',
        start: 'Iniciar',
        stop: 'Parar',
        wait: 'Aguarde, sem imagens disponíveis no banco de dados no momento',
        pressStart: 'Aperte em "Iniciar" para ver as imagens disponíveis no banco de dados ou envie uma imagem para adicionar um alerta manualmente',
        remove: 'Remover imagem',
        dropFile: 'Solte seu arquivo aqui ou clique para enviar',
        upload: 'Enviar imagem',
        avaiblesImages: 'imagens disponíveis',
        noImagesAvailable: 'Nenhuma imagem disponível no banco de dados',
        addWarning: 'Adicionar alerta',
        soundAlert: 'Alerta sonoro'

      },
      mordor: {
        btnRec: 'Gravação',
        recRequest: 'Solicitação de Gravações',
        recProtocol: 'Protocolo',
        recDatepick: 'Selecione o dia',
        recStart: 'Início',
        recEnd: 'Fim',
        recLocation: 'Local',
        recObs: 'Observações',
        recSend: 'Enviar solicitação',
        infoPreset: 'Preset (atual: ',
        infoAvailable: 'Disponível',
        infoLocation: 'Localização',
        infoStreamConsumed: 'Minutos consumidos de streaming:',
        infoConsumed: 'consumido',
        infoLastImgReq: 'Última requisição de imagem:',
        infoLastImgReceived: 'Última imagem recebida:',
        infoLastPreset: 'Última requisição de preset:',
        infoIR: 'Infravermelho',
        infoOpenStream: 'Abrir Streaming',
        infoCloseStream: 'Fechar Streaming',
        infoRequestFrame: 'Requisitar Frame',
        infoDownloadImg: 'Download da imagem', 
        dashSystem: 'Sistema', 
        dashLoading: 'Carregando...',
        dashAvailable: 'Disponibilidade',
        dashLastVer: 'Última verificação às ',
        dashPercentUsed: ' utilizado.',
        dashLastView: 'Últimas Visualizações',
        dashRequester: 'Solicitante',
        dashDate: 'Data',
        dashRecRequest: 'Requisições de Gravação',
        dashDateRequest: 'Data da requisição',
        dashPeriodRequest: 'Periodo requisitado',
        dashNoData: 'Sem dados.',
        dashReports: 'Relatórios',
        dashDriveLink: 'Link do drive.',
        dashVideos: 'Vídeos'


        
      }
    },
    "en-US": {
      login: {
        email: "Email",
        password: "Password",
        confirm: "Confirm",
      },
      navbar: {
        transmission: "Livestream",
        warnings: "Alerts",
        sauron: "Assisted SAURON",
        sauronAux: "Auxiliary SAURON",
        administration: "Management",
        operation: "Operation",
        mordor: "MORDOR on demand",
        language: "Language",
        logout: "Logout",
      },
      home: {
        loadingTransmissions: "Loading livestream... ",
      },
      fab: {
        mainTooltip: "Show options",
        georefTooltipOn: "Enable georeferencing",
        georefTooltipOff: "Disable georeferencing",
      },
      tour: {
        main: 'Welcome to ALTAVE Harpia! <br> This is a short tutorial to guide your navigation. Click "next" to proceed.',
        navbar:
          "This is the main navigation bar. Options to manage the operation appear here.",
        operation:
          "This is the Operation selector. It's possible to switch between available Operations on this account.",
        transmission: "The camera's livefeed appears here.",
        options:
          "This button shows additional options for the livestream. When available, you can click it and activate the camera's georeferencing.",
        labels: {
          buttonSkip: "Skip tutorial",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      sensors: {
        table: {
          id: "Sensor",
          time: "Last received alarm",
          elapsed: "Time since last alarm",
          elapsedHoursAgo: " hour(s) ago",
          message: "Alarm type",
          battery: "Battery voltage",
        },
        notification: {
          message: "There is movement near the sensors.",
        },
        legend: {
          falseAlarm: "FALSE ALARM",
          trueAlarm: "TRUE ALARM",
          seen: "SEEN",
          notSeen: "NOT SEEN",
          confirmed: "confirmed by operator",
          operator: "by operator",
        },
      },
      warnings: {
        calendar: {
          label: "Select a date",
          button: "Confirm",
        },
        card: {
          types: {
            person: "person | people",
            vehicle: "vehicle | vehicles",
            interest: "interest point | interest points",
            boat: "boat | boats",
            light: "light | lights",
            hotPoint: "hot point",
            fire: "fire | fire | fires",
            epi: "PPE"
          },
          detected: "detected",
          date: "Date",
          hour: "Hour",
          point: "Point",
          conjunction: "and",
          region: "Region not specified"
        },
      },
      management: {
        sidemenu: {
          dashboard: "Dashboard",
          userInsert: "Insert user",
          userModify: "Modify user",
          hirerInsert: "Insert operation",
          operationReport: "EORR/HORR",
          livestreamInsert: "Insert livestream",
          warningInsert: "Insert warning",
          reportUserPresence: "User access",
          reportOperationReport: "Flight availability",
          mordorOperationReport: "Mordor data",
          reportRonda: 'Ronda data',
          about: "About",
          mordorDashboard: "Mordor Dashboard",
          labels: {
            dashboard: "Dashboard",

            userControl: "User control",
            operationReport: "Operation report",
            livestreamControl: "Livestream control",
            warningControl: "Warning control",
            reports: "Reports",
            about: "About",
            requestrecording: "Request recordings",
          },
        },
        dashboard: {
          welcome: "Welcome, ",
          onlineUsers: "Online users now",
          userTable: {
            user: "User",
            state: "State",
            company: "Company"
          },
          card: {
            condition: "Status",
            flightNoProblem: "Flight time",
            anchored: "Anchored time",
            days: "days",
            hours: "hours",
            minutes: "minutes",
            seconds: "seconds",
            totalHours: "total flight time",
            and: "and",
          },
        },
      },
      opReport: {
        rhro: "HORR",
        rero: "EORR",
        type: "Type",
        opStatus: {
          flyNoProblemn: "Aerostat flying without problems",
          flyWithProblemn: "Aerostat flying with problems",
          aerostatDock: "Aerostat Docked",
          startFlight: "Start of Flight",
          altitudeChange: "Altitude Change",
          docking: "Docking",
        },
        author: "Author",
        dateTime: " Date and Time",
        problemOrigin: "Origin of the problem",
        factorIntern: "Internal factors",
        factorExtern: "External factors",
        monitoringRoutine: "Monitoring Routine",
        monitorStatus: {
          automaticTour: "Automatic Tour",
          manualMonitoring: "Manual Monitoring",
        },
        liveStream: "Livestream",

        dockingCause: "Docking reason",
        meteorologic: "Meteorology",
        repair: "Maintenance",
        preFly: "Pre-flight procedure",
        training: "Training",
        offWorkers: "Out of hours",
        othersExt: "Others - External Factors",
        othersInt: "Others - Internal Factors",
        repairType: "Maintenance type",
        repairCorrective: "Corrective maintenance",
        repairPrevent: "Preventive maintenance",
        equip: "Equipment",
        liveStatus: {
          operatingLive: "Operating with livestream",
          operatingNoLive: "Operating without livestream",
        },
        cableReleased: "Length of cable released (Meters)",
        hoursContinuos: "Continuous flight hours",
        minutesContinuos: "Continuous Flight minutes",
        modelCam: "Camera Model",
        irCooler: "IR Cooler camera",
        irStatus: {
          on: "On",
          off: "Off",
          dontExist: "Do not exist",
        },
        snGondola: "S/N Gondola",
        pnGondola: "P/N Gondola",
        extEnvelope: "External Envelope",
        dateInstallNylon: " External Envelope Installation Date",
        tpu: "TPU",
        dateInstallTPU: "Internal Envelope Installation Date",
        report: "Report",
        windyGo: " Go to Windy",
        windSpeed: "Windspeed (kt)",
        gusts: "Gusts (kt)",
        tupanGo: "Go to Tupan",
        tupanStatus: {
          flyAllow: "Flight Allowed",
          recoverAerostat: "Recover Aerostat",
          noInfo: "No Information",
        },
        localStation: "Local Weather Station",
        codebaGo: " Go to Local Weather Station ",
        codebaStatus: {
          windSpeed: "Windspeed (kt)",
          atmosphericPressure: "Atmospheric Pressure (hPa)",
          windDirection: "Wind Direction",
          windRose: " Wind Rose",
        },
        anemometer: "Anemometer",
        cemadenGo: "Go to CEMADEN",
        reflectivity: "Reflectivity (dBZ)",
        geocamStatus: {
          cableSlope: "Cable slope",
          aerostatAltitude: "Aerostat Altitude",
        },
        lightiningDetector: "Lightining Detector",
        distance: "Distance (km)",
        eletricalField: "Electrical Field",
        finish: "Finish",
        reportFinish: {
          windy: {
            windSpeed: "Windspeed",
            gusts: "Gusts",
          },
          tupan: {
            flightReleased: "Flight released",
            downBallon: "Docking aerostat",
            status: "Status",
          },
          anenomometer: {
            windSpeed: "Windspeed",
          },
          cemaden: {
            cloudReflex: "Clouds Reflectivity",
          },
          geocam: {
            cableSlope: "Cable Slope",
            aerostatAltitude: "Aerostat Altitude",
          },
        },
      },
      confirm: "Confirm",
      insertLive: {
        broadcastProvider: " Broadcast Provider",
        url: "URL/Stream Name",
        description: "Description",
        operation: "Operation",
      },
      insertWarning: {
        operation: "Operation",
        event: "Event",
        selectImage: " Choose image saved on the computer",
      },
      about: {
        title: "Quick guide",
        menubar: {
          overview: "Overview",
          insertUser: "Insert user",
          changeUser: "Change user",
          eorrHorr: "EORR/HORR",
          insertLivestream: "Insert Livestream",
          insertWarning: "Insert Warning",
          usersAccesses: "Users Accesses",
          flightAvailability: "Flight Availability",
        },
        permissions: {
            permissions: "Permissions",
            textInsertUser: "Field to specify access permissions to certain Harpia functions.",
            textChangeUser: 'Changes access permissions to certain Harpia functions.',
            streaming: "Streaming",
            streamingText: "Controls access to view the camera's live stream.",
            georef: "Georeferencing",
            georefText: "Accessing camera georeferencing in one operation.",
            cameraControl: "Camera Control",
            cameraControlText:
              "Controls camera control permission through Harpia.",
            viewAlerts: "View Alerts",
            viewAlertsText: "Controls the permission to view alerts.",
            deleteAlerts: "Delete Alerts",
            deleteAlertsText: "Controls the permission to delete alerts.",
            sauron: "Sauron",
            sauronText: "Controls access permission to assisted Sauron.",
            mordor: "Mordor",
            mordorText: "Controls access permission to Mordor on demand",
        },
        role: {
            role: "Role",
            textInsertUser: "Field for selecting the user's profile, based on the functions that will be performed at this login.",
            textChangeUser: "Field for modifying the user's profile, based on the functions to be performed by the user.",
            supervisorText: "Profile for operation supervisor users.",
            operator: "Operator",
            operatorText: "Profile for field operators of the operation.",
            viewer: "Viewer",
            viewerText: "Profile to use exclusively for views.",
          },
        overview:
          "The purpose of the Overview field, in the Dashboard context, is to display the users that are currently connected to Harpia and from which state the connection is being made.",
        insertUser: {
          text: "In the Insert User option, in the User Control context, the objective is to register users and their permissions.",
          name: "Name",
          nameText:
            "Field for entering the name of the user to be registered. This field cannot be changed later.",
          emailText:
            "Field for entering the user's email. Only one profile per registered email is allowed.",
          password: "Password",
          passwordText:
            "Field for entering the initial password. When registering, an email is sent to the user to replace the password.",
          operation: "Operation",
          operationText:
            "Field for selecting the operations that the registered user can perform.",
        },
        changeUser: {
          text: "In the Change User option, in the User Control context, the objective is to modify the permissions that users have or to delete the user from the system. It is not possible to change the name and email of users.",
          flag: "Flag",
          flagText:
            "Allows the inclusion and removal of operations that the registered user can perform.",
        },
        eorrHorr: {
          text: "In the Reports context, the EORR/HORR option is used to insert information regarding the operation. The fields below are displayed according to the option selected in status and can be filled in automatically, according to information from the previous report. After filling in the data on this form, a standardized message is automatically generated for sending via Whatsapp.",
          type: 'Type',
          typeText: 'Field for selecting the type of report being included.',
          status: 'Status',
          statusText: 'Field to select the status of the airship at the time of entry.',
          author: 'Author',
          authorText: 'Field to enter the name of the author of the report.',
          mooringReason: 'Mooring reason',
          mooringReasonText: 'Field to inform the reason why the airship is anchored.',
          problemOrigin: 'Problem origin',
          problemOriginText: 'Field to inform the origin of the problem that caused the anchoring.',
          monitoringRoutine: 'Monitoring routine',
          monitoringRoutineText: 'Field to select the type of monitoring being performed.',
          liveBroadcast: 'Live broadcast',
          liveBroadcastText: 'Field to inform if the broadcast is active.',
          cableReleased: 'Cable released',
          cableReleasedText: 'Field to inform the amount, in meters, of cable released from the airship.',
          flightTime: 'Flight time',
          flightTimeText: 'Divided into hours and minutes, these fields are for entering flight time.',
          plc: 'PLC',
          plcText: "Divided between download and upload, these fields are to inform the performance of the PLC's.",
          cameraData: 'Camera data',
          cameraDataText: 'Split between Model and IR Cooler, these fields are for reporting data from the camera that is currently in operation.',
          shelfData: 'Shelf data',
          shelfDataText: 'Divided between Serial Number and Part Number, these fields are for shelf information.',
          airshipData: 'Airship data',
          airshipDataText: 'Divided into four fields; TPU, Nylon and their respective installation dates. These fields are for airship information entries.',
          report: 'Report',
          reportText: 'Field for entering data, observations and other information that is relevant to the operation.',
          meteorologicalData: 'Meteorological Data',
          meteorologicalDataText: 'In this field, it is possible to insert information regarding the local meteorology, provided by several different sources, such as: Windy, Cemaden, Tupan and local measurement equipment.'
        },
        insertLivestream: {
          text: 'In the context of Stream Control, the Insert Stream option is used to start the live transmission of cameras by Harpia. To perform this insertion, the streaming broadcast must have been created on YouTube or Vimeo.',
          videoTransmissionURL: 'Video transmission URL',
          videoTransmissionURLText: 'In this field, enter the address of the video on the streaming platform.',
          description: 'Description',
          descriptionText: 'Field to enter the description of the video',
          operation: 'Operation',
          operationText: 'Field to select the operation to which you want the video to be transmitted.'
        },
        insertWarning: {
          text: 'In the context of Warning Control, the Insert Warning option is used to register images that contain any alert viewed during transmission.',
          latitude: 'Latitude',
          latitudeText: 'Field to insert the Latitude of the point where the alert was viewed.',
          longitude: 'Longitude',
          longitudeText: 'Field to enter the Longitude of the point where the alert was viewed.',
          operation: 'Operation',
          operationText: 'Field to select the operation to which the alert belongs.',
          occurrence: 'Occurrence',
          occurrenceText: 'Field for selecting the type of alert that was viewed.',
          chooseImage: 'Choose image saved on the computer',
          chooseImageText: 'Field used to save the image referring to the alert that is being registered.'
        },
        usersAccesses: {
          text: 'In the Reports context, the User Access option is used to extract a report that contains the login information of Harpia users. To extract these data, just select the date or period for which you want and confirm. The information provided is:',
          user: 'User',
          userText: 'Informs the user login.',
          region: 'Region',
          regionText: 'Informs the state to which the user used to access.',
          city: 'City',
          cityText: 'Informs the city to which the user used to access.',
          ip: 'IP Address',
          ipText: 'Informs the address used by the user.',
          action: 'Action',
          actionText: 'Informs the connection (online), disconnection (offline) action of the user.',
          date: 'Date',
          dateText: 'Informs the day the action was registered.',
          time: 'Time',
          timeText: 'Informs the time when the action was registered.'
        },
        flightAvailability: {
          text1: 'In the Reports context, the Flight Availability option is used to extract a report that contains information regarding the flight time, based on the reports entered in Harpia.',
          text2: 'To extract these data, just select the date or period for which you want and confirm. The information provided is:',
          type: 'Type',
          typeText: 'Informs the type of report that was entered.',
          date: 'Date',
          dateText: 'Informs the day the report was registered.',
          time: 'Time',
          timeText: 'Informs the time when the report was registered.',
          notes: 'Notes',
          notesText: 'Informs the data entered in the Report field of the EORR/HORR form.',
          altitude: 'Altitude',
          altitudeText: 'Informs the data entered in the released cable field of the EORR/HORR form.',
          meteorologicalData: 'Meteorological Data',
          meteorologicalDataText: 'Informs the data entered in the meteorological data field of the EORR/HORR form.',
          status: 'Status',
          statusText: 'Informs the data entered in the status field of the EORR/HORR form.',
          stopCause: 'Stop cause',
          stopCauseText: 'Informs the data entered in the Anchoring Reason field of the EORR/HORR form.',
          author: 'Author',
          authorText: 'Informs the author of the EORR/HORR form.'
        }
      },
      sauronAux: {
        target: 'Target',
        person: "Person",
        vehicle: "Vehicle",
        interestPoint: 'Interest point',
        hotpoint: 'Hotpoint',
        light: 'Light',
        boat: 'Boat',
        fire: 'Fire',
        save: "Save",
        undo: 'Undo',
        clean: 'Clean',
        start: 'Start',
        stop: 'Stop',
        wait: 'Please wait, no images available in the database at the moment',
        pressStart: 'Press "Start" to see the images available in the database or upload an image to manually add an warning.',
        remove: 'Remove image',
        dropFile: 'Drop your file here or click to upload',
        upload: 'Upload image',
        avaiblesImages: 'avaibles images',
        noImagesAvailable: 'No images available in the database',
        addWarning: 'Add warning',
        soundAlert: 'Sound alert'
      },
      mordor: {
        btnRec: 'Recording',
        recRequest: 'Recording Request',
        recProtocol: 'Protocol',
        recDatepick: 'Select day',
        recStart: 'Start',
        recEnd: 'End',
        recLocation: 'Location',
        recObs: 'Observations',
        recSend: 'Send request',
        infoPreset: 'Preset (current: ',
        infoAvailable: 'Available',
        infoLocation: 'Location',
        infoStreamConsumed: 'Streaming minutes consumed:',
        infoConsumed: 'consumed',
        infoLastImgReq: 'Last image requested:',
        infoLastImgReceived: 'Last image received:',
        infoLastPreset: 'Last preset modification:',
        infoIR: 'Infrared',
        infoOpenStream: 'Open Streaming',
        infoCloseStream: 'Close Streaming',
        infoRequestFrame: 'Request Frame',
        infoDownloadImg: 'Download image', 
        dashSystem: 'System', 
        dashLoading: 'Loading...',
        dashAvailable: 'Availability',
        dashLastVer: 'Last verified at ',
        dashPercentUsed: ' utilized.',
        dashLastView: 'Last views',
        dashRequester: 'Requester',
        dashDate: 'Date',
        dashRecRequest: 'Recording requests',
        dashDateRequest: 'Request date',
        dashPeriodRequest: 'Requested period',
        dashNoData: 'No data.',
        dashReports: 'Reports',
        dashDriveLink: 'Drive link.',
        dashVideos: 'Videos'
      }
    },
  },
});