import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "../../firebase.config"; //Import firebase to use its methods here to help on user navigation.

Vue.use(VueRouter);

// Array containing operations that need to be routed to '/warning'.
const operationsRoutedToWarning = [
  "ocyan",
  "oceanpact",
  "aker",
  "tellus",
  "jupiter",
  "carina",
  "bram",
  "mining hub",
  "ocyan_norbe_ix",
  "j mendes",
  "dof",
  "petrogroup",
  "baru",
  "syngenta",
  "seadrill",
];
const operationsRoutedToNewMordor = [
  "transpetro",
  "sabesp",
  "sabesp_cacapava",
  "sabesp_guararema",
  "puerta_de_oro",
];

//Array of available routes. All of which route is made by a path, name, component (which is a view) and a meta field.
//Every route but login requires for the user to be autenthicated.
const routes = [
  {
    path: "*",
    redirect: "/landing",
  },
  {
    path: "/landing",
    name: "Login",
    component: () => import("../views/Landing.vue"),
    meta: { requiresLogin: false },
    beforeEnter: async (to, from, next) => {
      //Fired before user enter this route. Used to check if an authenticated user is tryind to reach login page.
      if (await firebase.getCurrentUser()) next("/");
      //Check if there's a user logged in, because a logged in user shouldn't be on login page.
      else next(); //If there isn't a logged in user, let them continue their navigation.
    },
  },
  {
    path: "/",
    name: "Transmissão",
    component: () => import("../views/Home.vue"),
    meta: { requiresLogin: true },
    beforeEnter: async (to, from, next) => {
      //Check if user is a tower client. If so, they should not be allowed on / path.
      //WIP: Check for user's active flag instead of user's first slot flag.
      firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((idToken) => {
          const company = idToken.claims.userClaims.company.toLowerCase();
          const { newmordor, livestreamview, mordorondemand } =
            idToken.claims.userClaims.permissions;
          if (operationsRoutedToNewMordor.includes(company) || newmordor) {
            next("/newMordor");
          } else if (!livestreamview && mordorondemand) next("/mordor");
          else if (operationsRoutedToWarning.includes(company)) {
            next("/warning");
          } else next();
        });
    },
  },
  {
    path: "/management",
    name: "Administração",
    component: () => import("../views/Management.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/warning",
    name: "Alertas",
    component: () => import("../views/Warning.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/sauron",
    name: "Sauron Assistido",
    component: () => import("../components/sauron/SauronOperator.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/sauron-auxiliary",
    name: "Sauron Auxiliar",
    component: () => import("../components/sauron/SauronAuxiliary.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/mordor",
    name: "Mordor sob Demanda",
    component: () => import("../components/mordor/TheGrid.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/newMordor",
    name: "Mordor",
    component: () => import("../views/Mordor.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/reset",
    name: "Esqueci minha senha",
    component: () => import("../views/Reset.vue"),
    meta: { requiresLogin: false },
    beforeEnter: async (to, from, next) => {
      if (to.query.mode && to.query.oobCode && to.query.apiKey) {
        next();
      } else {
        next("/");
      }
    },
  },
];
let routerMode = window.location.hash.startsWith("#/Reset")
  ? "hash"
  : "abstract";
const router = new VueRouter({
  //Create router instance to export later.

  mode: routerMode,

  routes, //Pass routes array defined above to newly created router instance.
});

//Global navigation guard called before entering each and every one of the routes. Used to guard routes where login/especial permission are needed.
router.beforeEach(async (to, from, next) => {
  const requiresLogin = to.matched.some((record) => record.meta.requiresLogin); //Return either true or false if this route needs login or not.
  if (requiresLogin && !(await firebase.getCurrentUser())) {
    //Check if current route requires login and there isn't a user logged in using firebase.auth.js function.
    next("/landing"); //Pushes navigation to login page again.
  } else {
    next(); //Let user proceed with their navigation.
  }
});

export default router; //Export created router.
