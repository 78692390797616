//Imports used in this project.
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '../firebase.auth.js'
import '../firebase.analytics.js'
import Buefy from 'buefy' //Custom style and components.
import L from 'leaflet' //Map and markers.
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css' //Leaflet style for its maps.
import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js' //Required for showing Leaflet extra markers.
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css' //Required for showing Leaflet extra markers.
import { SnackbarProgrammatic as Snackbar } from 'buefy' //Buefy's snackbar outside vue instance.
import VueTour from 'vue-tour'
import i18n from './lang/i18n.config.js'
import VueGtag from 'vue-gtag'
import VueKonva from 'vue-konva';
import VueMobileDetection from 'vue-mobile-detection'


require('vue-tour/dist/vue-tour.css')

//Hack proposed on https://github.com/vue-leaflet/Vue2Leaflet/issues/28 to an issue which Leaflet markers wouldn't render correctly.
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

//Use Buefy with default icon pack to font-awesome.
Vue.use(Buefy, { defaultIconPack: 'fas' })
Vue.use(VueTour)
Vue.use(VueGtag, {
    bootstrap: false
}, router)
Vue.use(VueKonva)
Vue.use(VueMobileDetection)

//Global mixin to show user a prompt to update Harpia when a new version is out online.
//Works by watching on a window custom event sent by the service worker on its updatefound() piece of lifecycle.
Vue.mixin({
    data() {
        return {
            UpdateAvailable: false
        }
    },
    methods: {
        //Called when custom event 'serviceWorkerUpdated' fires to prompt user to update Harpia.
        //Receive service worker's registration status as parameter only to pass on to updateHarpia() method.
        showUpdateSnackbar(registration) {
            this.UpdateAvailable = true
            if (!this.UpdateAvailable) return
            Snackbar.open({
                message: 'Há uma nova versão do HARPIA disponível!',
                type: 'is-success',
                position: 'is-bottom',
                actionText: 'ATUALIZAR',
                indefinite: true,
                onAction: () => { this.updateHarpia(registration.detail) } //When user clicks 'ATUALIZAR' calls updateHarpia() method.
            })
        },
        //Called when user clicks 'ATUALIZAR' on prompt snackbar to update Harpia.
        //Receive service worker's registration status so it knows if it should update or not.
        updateHarpia(registration) {
            this.UpdateAvailable = false
            if (!registration || !registration.waiting) return //If a new service worker isn't trying to register itself or it isnt' waiting to be registered then return.
            registration.waiting.postMessage({ type: 'SKIP_WAITING' }) //If there's a service worker trying to register, send message for it to skip waiting and register.
        }
    },
    created() {
        window.addEventListener('serviceWorkerUpdated', this.showUpdateSnackbar, { once: true }) //Add a listener on 'serviceWorkerUpdated' emmited by service worker to know when prompt user with update snackbar.
        navigator.serviceWorker.addEventListener( //Add a listener on 'controllerchange' of service worker to know when control changed from old service work to newer one.
            'controllerchange', () => {
                window.location.reload(true) //Reload page.
            }
        )
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),


    mounted() {
        if (router.mode ==='abstract') this.$router.replace('/')
    },

}).$mount('#app')