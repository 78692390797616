import firebase from '../../../firebase.config.js'
import { database } from '../../../firebase.config.js'
import router from '../../router/index.js'
import axios from 'axios'

const state = {
    //User object.
    CurrentUser: {
        email: '', //User's email.
        name: '', //User's name.
        uid: '', //User's unique uid on Firebase.
        company: '', //User's company.
        flag: '', //User's flag array.
        activeFlag: '', //User's current active flag. Default is index 0 of user's flag.
        role: '', //User's role on HARPIA. Either admin, supervisor or commom user.
        permissions: { georefview: false, cameracontrol: false } //User's permissions on HARPIA.
    },
    IsAnyoneLoggedIn: false, //Hold either true or false wether there's a logged in user or not.
}

const getters = {
    GetCurrentUser(state) { //Return current logged in user.
        return state.CurrentUser
    },
    GetUserActiveFlag(state) {
        return state.CurrentUser.activeFlag
    }
}

const mutations = {
    LOGIN(state, user) {
        state.IsAnyoneLoggedIn = true
        state.CurrentUser = {
            email: user.email,
            name: user.name,
            uid: user.uid,
            company: user.company,
            flag: user.flag,
            activeFlag: user.activeFlag,
            activeFlagAlias: user.activeFlagAlias,
            activeFlagCamControl:user.activeFlagCamControl,
            activeFlagLivestream:user.activeFlagLivestream,
            activeFlagType:user.activeFlagType,
            role: user.role,
            permissions: user.permissions
        }
    },
    LOGOUT(state) { //Clear use object from store.
        state.IsAnyoneLoggedIn = false
        state.CurrentUser = {
            email: '',
            uid: '',
            company: '',
            flag: '',
            activeFlag: '',
            role: '',
            permissions: { georefview: false, cameracontrol: false }
        }
    },
    SWITCH_ACTIVE_FLAG(state, flag) {
        state.CurrentUser.activeFlag = flag.name
        state.CurrentUser.activeFlagAlias = flag.alias
        state.CurrentUser.activeFlagCamControl = flag.camControl
        state.CurrentUser.activeFlagLivestream = flag.livestream
        state.CurrentUser.activeFlagType = flag.type
    }
}

const actions = {
    //Action called only once when user attempts to login. Takes a user object with email and password as a parameter.
    Login({ state, dispatch }, payload) {
        if (state.IsAnyoneLoggedIn) return //Cancel login if user is somehow logged in.
        return firebase.auth().signInWithEmailAndPassword(payload.Email, payload.Password) //Use Firebase Auth method to login user with given email and password.
            .then(() => {
                dispatch('LogUserAction', { action: 'login', email: payload.Email })
            })
    },
    //This action is called by firebase.auth.js everytime there's a change on user's firebase token.
    //Used to bind a user to its livestream and populate state with user information.
    //Takes a user object from Firebase Auth as a parameter.
    async RefreshLogin({ dispatch, commit }, payload) {
        const token = await firebase.auth().currentUser.getIdTokenResult(true)
        const displayName = firebase.auth().currentUser.displayName
        const userClaims = token.claims.userClaims
        const userPermissions = token.claims.userClaims.permissions
        const { name, alias, livestream, type} = token.claims.userClaims.flag[0]
        dispatch('BindToCurrentUserFlag', {
            userActiveFlag: name,
            userActiveFlagType: type,
            userPermissions: userPermissions
        })
        dispatch('MarkUserOnline', payload)

        payload.name = displayName
        payload.company = userClaims.company
        const userObject = {
            email: payload.email,
            name: displayName,
            uid: payload.uid,
            company: userClaims.company,
            flag: userClaims.flag,
            activeFlag: name,
            activeFlagAlias: alias,
            activeFlagLivestream: livestream,
            role: userClaims.role,
            permissions: userClaims.permissions
        }
        commit('LOGIN', userObject) //Commit Firebase Auth user object to populate store state in mutation.
    },
    //Action called on RefreshLogin and SwitchCurrentUserActiveFlagto make priority binds to database.
    //Used to propagate user's flag changes over entire HARPIA.
    //Takes a object with a flag name and a user's permission object (custom claims).
    BindToCurrentUserFlag({ dispatch }, payload) {
        dispatch('database/bindCurrentLivestream', payload.userActiveFlag, { root: true }) //Dispatch action to database store module to bind livestreams to this user.
        dispatch('database/bindStopReport', payload.userActiveFlag, { root: true }) //Dispatch action to database store module to bind stop reports to this user.
        if (payload.userPermissions.georefview) { //If user permissions includes permission to view georef.
            // dispatch('database/bindCurrentGeoJSON', payload.userActiveFlag, { root: true }) //Dispatch action to database store module to bind geojson to this user ONLY IF THEY HAVE PERMISSION.
            dispatch('database/bindTourStatus', payload.userActiveFlag, { root: true })
            dispatch('database/bindSauronLimits', payload.userActiveFlag, { root: true }) ////Dispatch action to database store module to bind SAURON limits to this user.
        }
    },
    //Action called when user attempts to logout or when HARPIA recgonizes user isn't logged anymore and forces logout.
    //Both remove Firebase user token from browser local storage and user entry on logged-users on Realtime Database.
    //Takes a user object as a parameter to know wich user to remove from logged-users on Realtime Database.
    Logout({ commit, dispatch }, payload) {
        database.goOffline()
        database.goOnline()
        firebase.auth().signOut() //Use Firebase Auth method to logout user.
            .then(() => {
                database.ref('online-users/' + payload.uid).remove()
                    .then(() => {
                        database.ref('online-users').child(payload.uid).onDisconnect().cancel()
                        dispatch('LogUserAction', { action: 'logout', email: payload.email })
                        commit('database/CLEAR_DATABASE_STORE', null, { root: true }) //Commit mutation on database store to clear state there too.
                        router.replace('/landing') //Pushes navigation to login page again.
                    })
            })
            .catch(() => {}) //Supress warning generated by navigation.
    },
    //Action called when admin attempts to sign a new user up.
    //Takes a user object as a parameter.
    Signup(context, payload) {
        const signupCloudFunction = firebase.functions().httpsCallable('signUserUp') //Create a reference to a Cloud Function that takes a user object as a parameter and signs them up.
        return signupCloudFunction({ user: payload }) //Sign user up using a Cloud Function.
    },
    SendPasswordReset(context, payload) {
        return firebase.auth().sendPasswordResetEmail(payload)
    },
    ConfirmPasswordReset(context, payload) {
        return firebase.auth().confirmPasswordReset(payload.code, payload.password)
    },
    //Action called when a admin attempts to edit a user.
    //Takes a user object as a parameter.
    EditUser(context, payload) {
        let editUser = firebase.functions().httpsCallable('editUser') //Create a reference to a Cloud Function that takes a user object as a parameter and edit them.
        return editUser({ user: payload }) //Edit user using a Cloud Function.
    },
    DeleteUser(context, payload) {
        const deleteCloudFunction = firebase.functions().httpsCallable('deleteUser')
        return deleteCloudFunction(payload).then(() => { return database.ref('/users/' + payload).remove() })
    },
    //Action called whenever RefreshLogin is triggered.
    //Used to make an API call to ipapi.co to retrieve user information on IP address and geolocalization to further save it on Firebase making a presence system.
    //Takes a user object as parameter.
    MarkUserOnline(context, payload) {
        axios.get('https://ipapi.co/json/') //Call online API.
            .then(response => {
                database.ref('online-users').child(payload.uid).onDisconnect().remove() //Attach a onDisconnect trigger to current user's logged-users reference.
                database.ref('online-users/' + payload.uid).set({ user: payload.email, name: payload.displayName, company: payload.company, ip: response.data.ip, region: response.data.region, city: response.data.city, })
                // .then(() => { dispatch('LogUserAction', { action: 'online', email: payload.email }) })
            })
            .catch({})
    },
    //Action called whenever user does a important action on HARPIA such as login, logout, etc.
    //Used to log actions on Firebase with user's IP address and geolocalization.
    //Takes a log object as a parameter containing info about the action user took.
    LogUserAction(context, payload) {
        axios.get('https://ipapi.co/json/') //Call online API.
            .then(response => {
                let currentDate = new Date().toLocaleDateString('pt-BR').split('/').join('-')
                let LogObject = { user: payload.email, action: payload.action, hour: firebase.database.ServerValue.TIMESTAMP, ip: response.data.ip, region: response.data.region, city: response.data.city }
                database.ref('/actions-log/' + currentDate).push({ LogObject })
                database.ref('/actions-log/actions-log-index/' + currentDate).set(true)

            })
            .catch({})
    },
    //Action called when a user switches their current active flag.
    //Takes a object with a flag name and a user's permission object (custom claims) to use later when calling BindToCurrentUserFlag action.
    //Before setting up a new active flag, this action check if user has access to the flag it wants to change to.
    SwitchCurrentUserActiveFlag({ dispatch, commit }, payload) {
        firebase.auth().currentUser.getIdTokenResult(true).then(idToken => { //Access permission object using getIdTokenResult to get custom claims later.
            let userPermissions = idToken.claims.userClaims.permissions //Store permissions from custom claims on a variable.
            let userFlag = idToken.claims.userClaims.flag //Store flags from custom claims on a variable.
            let userFlagNames = [] //Create empty array to house flag names.
            userFlag.forEach(flag => { userFlagNames.push(flag.name) }) //Iterate over flags from custom claims to push only their names to array created above.
            if (!userFlagNames.includes(payload.name)) return //Finally, it's possible to check if flag passed as paramater to this action is included in user's custom claims.
            commit('SWITCH_ACTIVE_FLAG', payload) //Commit flag passed as a parameter passed to this action as the new active flag.
            return dispatch('BindToCurrentUserFlag', { userActiveFlag: payload.name, userPermissions: userPermissions }) //Call action to make all bindings again with the new flag.
        }).then(() => {
            database.goOffline()
            database.goOnline()
            dispatch('MarkUserOnline', firebase.auth().currentUser)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}