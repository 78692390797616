import firebase from './firebase.config.js' //Import firebase so it is possible to use Auth methods.
import { setOptions, bootstrap } from 'vue-gtag'

firebase.getCurrentUser().then(user => {
    user.getIdTokenResult(true).then(idToken => {
        let userActiveFlagAlias = idToken.claims.userClaims.flag[0].alias //Hold user's flag alias from custom claims.
        const userClaims = idToken.claims.userClaims
        const displayName = firebase.auth().currentUser.displayName
        setOptions({
            config: {
                id: 'G-7Y85PLDZ97',
                params: {
                    'user': displayName,
                    'role': userClaims.role,
                    'company': userClaims.company,
                    'operation': userActiveFlagAlias
                }
            }
        })
        bootstrap().then(() => {})
    })
})