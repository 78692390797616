import { database } from '../../../firebase.config.js' //Import firebase's realtime database instance so it's possible to push to and retrieve from it.
import { firebaseAction } from 'vuexfire' //Vuexfire action bundled with mutation to make call to firebase realtime's database and alter store state.

const state = {
    AvailableImages: [],
    AvailableImagesAuxiliary: []
}   

const getters = {
    GetAvailableImages(state) {
        return state.AvailableImages
    },
    GetAvailableImagesAuxiliary(state) {
        return state.AvailableImagesAuxiliary
    }
}

const actions = {
    bindAvailableImages: firebaseAction(({ bindFirebaseRef},payload) => {
        let flag =payload.activeFlag.toLowerCase()
        
        
        return bindFirebaseRef('AvailableImages', database.ref('sauron/'+flag+'-backlog').limitToLast(30))
        //return bindFirebaseRef('AvailableImages', database.ref('sauron/altave-backlog').limitToLast(30))
        // return bindFirebaseRef('AvailableImages', database.ref('sauron/codeba-backlog').limitToLast(30))
    }),
    unbindAvailableImages: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('AvailableImages')
    }),

    // Sauron auxiliar
    unbindAvailableImagesAuxiliary: firebaseAction(({ unbindFirebaseRef }) => {
        unbindFirebaseRef('AvailableImagesAuxiliary')
    }),
    bindAvailableImagesAuxiliary: firebaseAction(({ bindFirebaseRef},payload) => {
        let flag =payload.activeFlag.toLowerCase()
        
        
        return bindFirebaseRef('AvailableImagesAuxiliary', database.ref('sauron/'+flag+'-auxiliar').limitToLast(30))
        //return bindFirebaseRef('AvailableImages', database.ref('sauron/altave-backlog').limitToLast(30))
        // return bindFirebaseRef('AvailableImages', database.ref('sauron/codeba-backlog').limitToLast(30))
    }),
    markImageAsClassified(context, payload) {
        return database.ref('mordor1/' + payload).update({ classified: true })
    },
    removeImageFromList(context, path,payload) {
        let flag =payload.activeFlag.toLowerCase()
        return database.ref('sauron/'+flag+'-backlog/' + path).remove()
    },
    pushImageToClassifiedList(context, payload) {
        return database.ref('mordor1-classified/' + payload.path).set(payload.image)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions
}