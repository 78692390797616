//-------------------------------------------------------
// PRODUCTION - UNCOMMENT IN PRODUCTION
//-------------------------------------------------------
// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/database'
// import 'firebase/functions'
// import 'firebase/storage'
// import 'firebase/analytics'


// firebase.initializeApp({
// apiKey: "AIzaSyA_ThNkXE1JDFletEOd97ltg6nmSa-z_60",
// authDomain: "altave-harpia.firebaseapp.com",
// databaseURL: "https://altave-harpia.firebaseio.com",
// projectId: "altave-harpia",
// storageBucket: "altave-harpia.appspot.com",
// messagingSenderId: "497957629587",
// appId: "1:497957629587:web:e8586059bb3e18fb"
// })


// export default firebase
// export const database = firebase.database()
// export const storage = firebase.storage()
// export const analytics = firebase.analytics()

//-------------------------------------------------------
//DEVELOPMENT: COMMENT IN PRODUCTION. UNCOMMENT IN DEVELOPMENT/EMULATOR
//-------------------------------------------------------

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

firebase.initializeApp({
  apiKey: "AIzaSyA_ThNkXE1JDFletEOd97ltg6nmSa-z_60",
  authDomain: "altave-harpia.firebaseapp.com",
  databaseURL: "https://altave-harpia.firebaseio.com",
  projectId: "altave-harpia",
  storageBucket: "altave-harpia.appspot.com",
  messagingSenderId: "497957629587",
  appId: "1:497957629587:web:e8586059bb3e18fb"
})

export default firebase
export const database = firebase.database()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const auth = firebase.auth();
export const functions = firebase.functions()

// auth.useEmulator("http://localhost:9099");
// database.useEmulator("localhost", 9000);
// storage.useEmulator("localhost", 9199);
// functions.useEmulator("localhost", 5001);
